const InitialState = {
  count: 0,
  loading: false,
  error: null,
  payload: null,
  ittinaMahavir: { isLoading: false, payload: null, error: null },
  leads: { isLoading: false, payload: null, error: null },
};

export default InitialState;
